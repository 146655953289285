import React from 'react'

const NotFoundPage = () => {
    return (
        <div>
            <h1>Page not found (404)</h1>
        </div>
    )
}

export default NotFoundPage